body {
  background-color: #2c2c2c;
}

.TextOutline {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.App {
  position: absolute;
  width: 100%;
  height: 100%;

  overflow-x: hidden;
  overflow-y: scroll;

  text-align: center;
  background: url('./assets/images/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
}

.LandingImage-Frame {
  height: 700px;
  position: relative;
  max-width: 100%;
  max-height: 100%;
}

.Curtain-holder {
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.Curtain-NestedElement {
  height: calc(100% * (1 - 232 / 980 * 2));
  width: calc(100% * (1 - 232 / 980 * 2));

  top: calc(100% * (232 / 980));
  left: calc(100% * (232 / 980));
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

.Title {
  margin-top: 100px;
  margin-bottom: 20px;
  padding: 20px 40px;
  background-color: #feda00;
  color: #000;
  font-size: 30px;
}

.ConnectMetamaskTitle {
  margin-top: 0;
}

.BackgroundTitleSection {
  margin: 0 0 20px;
}

.BearIdTitle {
  margin: 0 20px;
}

.ChooseBackgroundTitleSection {
  margin: 20px 0 0;
}

.ChooseBackgroundTitle {
  margin: 10px 0 0;
  font-size: large;
}

a.ChooseBackgroundLink {
  color: #feda00;
}

.PhotoboothTitle-section {
  max-width: 100%;
  width: 700px;
}

.Selector-section {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Subtitle-section {
  margin-top: 80px;
  display: flex;
  width: 100%;
}
.Subtitle-container {
  display: flex;
  width: 50%;
}

.Supply-text {
  text-align: left;
  margin-right: auto;
}
.Price-text {
  text-align: right;
  margin-left: auto;
}

.image-18 {
  width: 135px;
}

.image-dropbears-logo {
  width: 102px;
}

.social-icon {
  width: 50px;
  padding-left: 20px;
}

.Footer-container {
  display: flex;
  width: 80%;
  margin: auto;
  margin-top: 140px;
}

.Footer-section {
  display: flex;
  align-items: center;
  width: 33.33333333%;
}

.Text-center {
  justify-content: center;
}

.Text-right {
  justify-content: flex-end;
}

.text-purchase {
  margin-top: 40px;
  font-family: 'Droid Sans', sans-serif;
  color: #fff;
  font-size: 12px;
  text-align: center;
}

.MuiButton-contained.Mui-disabled.SelectBackgroundButton {
  background-color: transparent;
}

.MuiButton-contained.SelectBackgroundButton,
.MuiButton-contained.SelectBackgroundButton:hover,
.MuiButton-contained.SelectBackgroundButton:focus {
  position: relative;
  margin: 0 20px !important;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;

  font-size: 24px;
  padding: 0;
  background: transparent;
  box-shadow: none;
}

.MiddleButtonImage {
  height: 70px;
}

.MuiButton-contained.ConnectMetamaskButton {
  margin-left: 10px !important;
  background-color: #feda00;
  color: #000;
}

.MiddleButtonText {
  position: absolute;
  display: flex;
}

.MuiSelect-outlined.MuiSelect-outlined,
.MuiSelect-select:focus {
  background-color: white !important;
  border-radius: 4px;
}

.MuiFormControl-root {
  min-width: 180px !important;
}

.WalletText {
  font-size: 18px;
  margin-bottom: 140px;
  color: #feda00;
}

.WalletText-reduced {
  margin-bottom: 20px;
}

.LoadingText {
  font-size: 18px;
  color: #feda00;
}

.Selector-ToggleSection {
  margin-bottom: 40px;
}
